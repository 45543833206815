import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'
import {UserContext} from '../../../context/UserContext'

export default function Header() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
    const [articles,setArticles]=useState([])
    const [reactions,setReactions]=useState([])
    const [postreactions,setPostReactions]=useState([])
    const [articlesFlt,setArticlesFlt]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [scrollY, setScrollY] = useState(0);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [image, setImage] = useState(null);
    const [stories,setStories]=useState([])
    const {usersWhoBlockedMe,setUsersWhoBlockedMe}=useContext(UserContext)
    const {loadUsersWhoBlockedMe}=useContext(UserContext)
    const {newChallenge,setNewChallenge}=useContext(UserContext)
    const [friends,setFriends]=useState([])
    const [searchText,setSearchText]=useState('')
    const { loadUnreadNotifications}=useContext(UserContext)
    const navigate=useNavigate()
    const vidEl=useRef()
    const s=useRef()
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [levelDropdownOpen, setLevelDropdownOpen] = useState(false);



    const search=()=>{
        if (searchText.length>0){
          //console.log(sportID)
          navigate('/searchresults/'+searchText.trim())
        }
        
      }
    
      const changeSearchText=(e)=>{
        setSearchText(e.target.value)
      }
    
      const handleKeyDown=(e)=>{
        if (e.key==='Enter'){
          search()
        }
      }

  return (
    <header>
          <div className="upper-section bg-white">
            <div className="d-flex align-items-center">

              <div className="upper-texts">
                <div className="text1-w"><span style={{color:'#f95d2d', fontWeight:'bold'}}>&nbsp;</span><span style={{color: 'grey'}}></span></div>
                <div className="text2-g fw-bold">Training</div>
              </div>
            </div>
            
            <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+`${process.env.REACT_APP_API_SERVER}files/${userID}/1234`+')'}}>
          </div>
          </div>               
    </header>
  )
}
