import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {UserContext} from '../../../context/UserContext'
import PullToRefresh from 'react-simple-pull-to-refresh'
import axios from 'axios'
import otthLogo from '../../../css/images/logo-b-orange.jpg'

import loading from '../../../css/images/loading.gif'
import loadingb from '../../../css/images/loadingb.gif'


import Header from './HeaderTraining'
import WallCoachVideo from './WallCoachVideo';


export default function Home2Coach() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
  const {itemToView,setItemToView}= useContext(UserContext)
  const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
  const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
  const [articles,setArticles]=useState([])
  const [reactions,setReactions]=useState([])
  const [postreactions,setPostReactions]=useState([])
  const [articlesFlt,setArticlesFlt]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [scrollY, setScrollY] = useState(0);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [image, setImage] = useState(null);
  const [stories,setStories]=useState([])
  const {usersWhoBlockedMe,setUsersWhoBlockedMe}=useContext(UserContext)
  const {loadUsersWhoBlockedMe}=useContext(UserContext)
  const {newChallenge,setNewChallenge}=useContext(UserContext)
  const [friends,setFriends]=useState([])
  const [searchText,setSearchText]=useState('')
  const { loadUnreadNotifications}=useContext(UserContext)
  const [page,setPage]=useState(1)
  const [sponsors,setSponsors]=useState([])
  const {socket,setSocket}=useContext(UserContext)
  const navigate=useNavigate()
  const vidEl=useRef()
  const s=useRef()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [levelDropdownOpen, setLevelDropdownOpen] = useState(false);
  const [filter,setFilter]=useState('')
  const [level,setLevel]=useState('')


  let lastScrollTop = 0;
  let lastUpScrollTop = 0;
  let offsetToShow = 120; // �� ÷���� õ Pixels ��� �� ��Ʊ���ĵ� Ŀ header ���� 
  let scrollUpThreshold = 20; // �� ���� õ pixel ��� �ͻ�÷ ���� ı ����

  let ticking = false;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4
    }
  };

  useEffect(()=>{

    //loadData()
    refresh().then(r=>{
      setIsLoading(false)
    }).catch(err=>{
      setIsLoading(false)
    })

    const images = document.querySelectorAll('.dynamic-image');
    images.forEach(img => {
        img.onload = function() {
            if (this.naturalWidth >= 1080) {
                this.classList.add('wide');
                console.log(`Class 'wide' added to image: ${this.src}`);
            } else {
                this.classList.add('narrow');
                console.log(`Class 'narrow' added to image: ${this.src}`);
            }
            adjustParentHeight(this);
        };
        img.onerror = function() {
            console.error(`Failed to load image: ${this.src}`);
        };
    });




    function adjustParentHeight(img) {
      const parent = img.closest('.home2-post-image');
      parent.style.height = img.height + 'px';
  }
  



  if (document.querySelector('.upper-section.bg-white') && document.querySelector('footer')){
    window.addEventListener('scroll', handleScroll);
    //console.log(1)
  }
		

    window.addEventListener('fullscreenchange', handleFullScreenChange);
  
    return () => {
      window.removeEventListener('scroll',handleScroll);
       window.removeEventListener('fullscreenchange', handleFullScreenChange);
     };
  },[])

  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  
  function isVideoInView() {
    var videos = document.getElementsByTagName('video');
    for (var i = 0; i < videos.length; i++) {
      if (isElementInViewport(videos[i])) {
        
  
        for (var k = 0; k < videos.length; k++) {
          videos[k].pause();
        }
  
        return true;
      }
    }
    return false;
  }

  //-------------------FULL SCREEN CHANGE-------------------------------------------

  const handleFullScreenChange=()=>{
    window.scrollTo({
      top: sessionStorage.getItem('prevScrollY'),//prevScrollY
    });  


    if (document.fullscreenElement){
      window.scrollTo({
        top: sessionStorage.getItem('prevScrollY')+1,//prevScrollY
      });  
    }else{
      window.scrollTo({
        top: sessionStorage.getItem('prevScrollY')+1,//prevScrollY
      });  
    }
  }
//-----------------------SCROLL FUNCTIONS---------------------------------------------
const handleScroll=()=>{
  //alert(ticking)
  if (!ticking) {
    window.requestAnimationFrame(onScroll);
    ticking = true;
  }
}

const onScroll=()=> {
  //try{
    let header = document.querySelector('.upper-section.bg-white');
    let footer = document.querySelector('footer');

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;


    if (scrollTop > lastScrollTop) {
        // Scrolling down
        if (scrollTop > offsetToShow) {
            header.style.transform = 'translateY(-100%)';
            footer.style.transform = 'translateY(100%)';
        }
    } else {
        // Scrolling up
        if (scrollTop < offsetToShow || (lastUpScrollTop - scrollTop > scrollUpThreshold)) {
            header.style.transform = 'translateY(0)';
            footer.style.transform = 'translateY(0)';
        }
        lastUpScrollTop = scrollTop; // Update lastUpScrollTop ���� �ı� ����ı� scrolling up
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    ticking = false;
    isVideoInView()
  // }catch(err){

  // }

}




  const refresh=()=>{
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'coachesvideos/'+sportID+'/'+langID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
        if (res.data.err==0){
          //console.log(res.data.results)
          setArticles(res.data.results)
          setArticlesFlt(res.data.results)
        }else{
          alert('Error)')
        }

        
        
        setIsLoading(false)
        resolve(1)
      }).catch(err=>{
          setIsLoading(false)
          reject(0)
      }) 
    })
  }







  const articlesList=articlesFlt.map((item,index)=>{

    const articlePhoto=`${process.env.REACT_APP_API_SERVER}admin/files/${item.UUID}/123`
    const author='123High'
    const authorImg=otthLogo

    return(<WallCoachVideo  key={`wpi${index}`} index={index} item={item} articlePhoto={articlePhoto} author={author} authorImg={authorImg}/>)

  })




  const storiesList=stories.map((item,index)=>{
    if (item.IsChallenge===1){
      var articlePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'
    }else{
      if (item.VideoID.length>0){
        var articlePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'
      }else{
        var articlePhoto=process.env.REACT_APP_API_SERVER+'postfiles/'+item.UUID+'/123'
      }
      
    }
    
    const endPoint=item.IsChallenge===1 ? 'challengeprofilestory' : 'postprofilestory'
    return(

      // <div key={`story${index}`} onClick={()=>navigate('/'+endPoint+'/'+item.UUID+'/'+langID+'/'+item.UsrUUID)}>
       <div key={`story${index}`} onClick={()=>viewItem(item)}>
          <div className="card rounded" style={{backgroundImage:'url('+articlePhoto+')'}} > 
          <div className="card-img-overlay overlay-text">{item.FirstName} {item.LastName}</div> 
          </div>
      </div>
      )


  })


  const viewItem=(item)=>{
    const itemObj={
      Type:item.Type,
      ItemUUID:item.UUID,
      ItemID:item.ChallengeID,
      VideoID:item.VideoID,
      Photo:item.Photo,
      UsrUUID:item.UsrUUID,
      UsrName:item.FirstName + " " + item.LastName,
      Descr:item.Descr,
      Title:item.Title,
      Reactions:item.reactions,
      Page:'/home'
    }

    //console.log(itemObj)

    setItemToView(itemObj)
    navigate('/content')
  }

// -------------Filters & level--------------
        const toggleDropdown = () => {
            setDropdownOpen(!dropdownOpen);
        };
        
        const toggleLevelDropdown = () => {
            setLevelDropdownOpen(!levelDropdownOpen);
        };

        const filters = sportID === '2' ? [ 
          "Defending",
          "Dribbling",
          "Fake dribble or shoot",
          "Foot Movement",
          "Free Throws",
          "Jumping",
          "Layups",
          "Low Post Moves",
          "Passing",
          "Rebounding",
          "Reflexes",
          "Shooting"
          
    
        ] : [ 
        "Ball control",
        "Defending",
        "Diving (Gk)",
        "Dribbling",
        "Endurance",
        "Footwork",
        "Heading",
        "High Ball (Gk)",
        "Passing and Receiving",
        "Speed",
        "Shooting",
        "Shot stopping (Gk)",
        "Reflexes (Gk)"          
        ];

        const levels = ["Beginner", "Intermediate", "Advanced", "Pro"];

        const selectFilter=(f)=>{
          setFilter(f)
          setDropdownOpen(!dropdownOpen)

        
            var flt=articles.filter(item=>item.Tags.includes(f))

            if (level.length>0){
                flt=flt.filter(item=>item.Level.includes(level.substring(0,2)))
            }

            setArticlesFlt(flt)
          
        }

        const selectLevel=(l)=>{
          setLevel(l)
          setLevelDropdownOpen(!levelDropdownOpen);

          var flt=articles

          if (l.length>0){
            flt=flt.filter(item=>item.Level.includes(l.substring(0,2)))
          }
          

          if (filter.length>0){
            flt=flt.filter(item=>item.Tags.includes(filter))
          }

          setArticlesFlt(flt)
        }

  return (

      <>
      <Header/>
      <PullToRefresh onRefresh={refresh}>





        <section className="section-margin" style={{marginTop:storiesList.length===0 ? 40 : 0}}>
            <div className='d-flex justify-content-between align-baseline w-100 mt-2 filter-wrap' style={{padding:'10px 20px 30px 20px'}}>
                    <div className="dropdown" style={{width: '70%'}}>
                        <button className="btn  dropdown-toggle d-flex justify-content-between align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleDropdown} style={{border: '1px solid #f95d2d', width:'100%', height:"35px", textAlign:"left"}}>
                        {filter.length==0 ? 'Filter' : filter}
                        </button>
                        <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                        <li ><a className="dropdown-item" onClick={()=>selectFilter('')}>[Filter]</a></li>
                        {filters.map((xfilter, index) => (
                            <li key={index}><a className="dropdown-item" onClick={()=>selectFilter(xfilter)}>{xfilter}</a></li>
                        ))}
                        </ul>
                    </div>       

                    <div className="dropdown dropdown-level">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="levelDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleLevelDropdown} style={{ backgroundColor: '#3d3d3d', borderColor: '#3d3d3d', height:"35px", fontSize:"smaller",  width: "100%", marginBottom:"10px" }}>
                           {level.length==0 ? 'Level' : level}
                        </button>
                        <ul className={`dropdown-menu ${levelDropdownOpen ? 'show' : ''}`} aria-labelledby="levelDropdownButton" >
                            <li ><a className="dropdown-item" onClick={()=>selectLevel('')}>[Level]</a></li>
                            {levels.map((xlevel, index) => (
                                <li key={index} ><a className="dropdown-item" onClick={()=>selectLevel(xlevel)} style={{color:"#3d3d3d", fontWeight:"bold", fontSize:"14px"}}>{xlevel}</a></li>
                            ))}
                        </ul>
                    </div>
            </div>


        {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <p align="center">
                <img src={sportID===2 ? loadingb : loading} width="100"/>
              </p>
            </div> 
            </>              
        }


        {!isLoading ? articlesList.length>0 ? articlesList : <div dangerouslySetInnerHTML={{ __html: '<hr><p align="center"><b>No results</b></p><hr>'}}/> : ''}

        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>


        </section>
        
        </PullToRefresh>
      </>
 
    
  )
}
