import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {UserContext} from '../../../context/UserContext'
// import Footer from '../../Components/Footer'
import axios from 'axios'
import moment from 'moment'

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import cube3 from '../../../css/images/cube3.png'
import cube2 from '../../../css/images/cube2.png'
import foutline from '../../../css/images/f-outline.png'
import thunder7w from '../../../css/images/thunder7-w.png'
import medal3w from '../../../css//images/medal3-w.png'

import foutline2 from '../../../css/images/f-outline2.png'
import thunder5 from '../../../css/images/thunder5.png'
import achievement from '../../../css/images/achievement.png'

import octagon from '../../../css/images/octagon.png'

import flame from '../../../css/images/flame.png'

export default function WallCoachVideo(props) {
  return (
    <div className="home2-posts-item" >

        <div className="home-avatar-item bg-white">

            <div className="home-header">
                <div className="home-header-text">{props.author}</div>
                <div className="home-header-time d-flex">{moment(props.item.DateCreated).format('DD/MM/YYYY')} 
                    <i className="bi bi-globe-americas ps-1" style={{color:'black'}}></i>
                    {/* <img className="ps-1" src={cube3} alt="cube icon" style={{height:'14px'}}/> */}
                    {props.item.Type==3 &&
                    <img className="ps-1" src={cube2} alt="cube icon orange" style={{height:'14px'}}/>
                    }
                </div>
            </div>

        </div>
        <div className="home2-user-line"></div>
        <div className="home-practice-days bg-white">

            {(props.item.Title.length>0 || props.item.Descr.length>0) &&
                <>
                
                <b>{props.item.Title}</b><br/>
                {props.item.Descr.length>0 &&
                <div
                dangerouslySetInnerHTML={{ __html: props.item.Descr.replace(/\n/g, '<br>') }}

                />
                }
                <i>{props.item.Tags} // {props.item.Level}</i>
                <br/>
                </>
            }


            </div>


        <div className="home2-user-line"></div>
        <div className="home2-post-image">
                <video preload="false" playsInline type="video/mp4" id="videoPlayer" controls width="100%" height="100%" poster={process.env.REACT_APP_VIDEOSERVER_API+''+props.item.VideoID+'.jpg'}>
                    <source src={`${process.env.REACT_APP_VIDEO_BASE_URL}${props.item.VideoID}.smil/playlist.m3u8#t0.01`} type="video/mp4"/>
                </video>
        </div>
    </div>
  )
}
