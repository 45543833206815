import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'
import {UserContext} from '../../../context/UserContext'

export default function HeaderTraining() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
    const [articles,setArticles]=useState([])
    const [reactions,setReactions]=useState([])
    const [postreactions,setPostReactions]=useState([])
    const [articlesFlt,setArticlesFlt]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [scrollY, setScrollY] = useState(0);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [image, setImage] = useState(null);
    const [stories,setStories]=useState([])
    const {usersWhoBlockedMe,setUsersWhoBlockedMe}=useContext(UserContext)
    const {loadUsersWhoBlockedMe}=useContext(UserContext)
    const {newChallenge,setNewChallenge}=useContext(UserContext)
    const [friends,setFriends]=useState([])
    const [searchText,setSearchText]=useState('')
    const { loadUnreadNotifications}=useContext(UserContext)
    const navigate=useNavigate()
    const vidEl=useRef()
    const s=useRef()
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [levelDropdownOpen, setLevelDropdownOpen] = useState(false);



    const search=()=>{
        if (searchText.length>0){
          //console.log(sportID)
          navigate('/searchresults/'+searchText.trim())
        }
        
      }
    
      const changeSearchText=(e)=>{
        setSearchText(e.target.value)
      }
    
      const handleKeyDown=(e)=>{
        if (e.key==='Enter'){
          search()
        }
      }

      
    //   const toggleDropdown = () => {
    //       setDropdownOpen(!dropdownOpen);
    //     };
        
    //     const toggleLevelDropdown = () => {
    //       setLevelDropdownOpen(!levelDropdownOpen);
    //   };

    // const filters = sportID === '1' ? [ // Assuming 1 is Basket
    //     "Defending", "Dribbling", "Fake dribble or shoot", "Foot Movement",
    //     "Free Throws", "Jumping", "Layups", "Low Post Moves", "Passing",
    //     "Rebounding", "Reflexes", "Shooting"
    // ] : [ // Otherwise, assume Football
    //     "Ball control", "Defending", "Diving (Gk)", "Dribbling", "Endurance",
    //     "Footwork", "Heading", "High Ball (Gk)", "Passing and Receiving", "Speed",
    //     "Shooting", "Shot stopping (Gk)", "Reflexes (Gk)"
    // ];

    // const levels = ["Beginner", "Intermediate", "Advanced", "Pro"];
      
   // console.log("sportID:", sportID)
  return (
    <header>
        <div className="home-upper-section2 bg-white">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="upper-texts">
                        <div className="text2-g">Hi, {firstName}!</div>
                        <div className="home-upper-about">
                            <div className="home-upper-about-in">{sessionStorage.getItem('WelcomeText')}</div> 
                        </div>
                    </div>
                </div>
                <div onClick={()=>navigate('/profile/'+userID)} className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')',minWidth:'60px'}}></div>  
            </div>
            
            <div className="home-post">
                <form>
                    <div className="home-post-box">
                        <textarea type="text" placeholder="Search for friends" onKeyDown={(e)=>handleKeyDown(e)} value={searchText} onChange={(e)=>changeSearchText(e)} style={{alignContent:"center"}}></textarea>  
                        <div className="home-post-box-buttons">
                            <button onClick={(e)=>{e.preventDefault();search()}} style={{backgroundColor: '#f95d2d', border:'1px solid #f95d2d'}}><i className="bi bi-search" style={{fontSize: '11px', fontWeight: 'bold', color:'white'}}></i></button>
                        </div>
                    </div>
                    <div className="d-flex ms-3 align-items-center">
                        <div className='me-2' >
                            <button onClick={()=>navigate('/homepagepost')} style={{backgroundColor: '#f95d2d', border: '1px solid #f95d2d', display:"flex", color:"white", borderRadius: '5px',height:'35px', alignItems:"center", fontWeight:"bold", fontSize: '13px'}}>POST <i className="bi bi-plus" style={{background:"#fff", color: '#f95d2d', borderRadius: '50%', width:"20px", height:"20px", marginLeft:"5px", fontSize: 'large'}}></i></button>
                        </div>
                        <div>
                            <button onClick={()=>navigate('/chat')} style={{backgroundColor: '#f95d2d', border: '1px solid #f95d2d', display:"flex", color:"white", borderRadius: '5px',height:'35px', alignItems:"center", fontWeight:"bold", fontSize: '13px'}}>
                            CHAT <i className="bi bi-chat-dots" style={{color: 'white', borderRadius: '50%', width:"20px", height:"20px", marginLeft:"5px", fontSize: 'large'}}></i></button>
                        </div>
                    </div>
                </form>
            </div>
            {/* <div className='d-flex justify-content-between align-baseline w-100 mt-2'>
                <div className="dropdown" style={{width: '70%'}}>
                    <button className="btn  dropdown-toggle d-flex justify-content-between align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleDropdown} style={{border: '1px solid #f95d2d', width:'100%', height:"35px", textAlign:"left"}}>
                    Filter
                    </button>
                    <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                    {filters.map((filter, index) => (
                        <li key={index}><a className="dropdown-item" href="#">{filter}</a></li>
                    ))}
                    </ul>
                </div>       

                <div className="dropdown dropdown-level">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="levelDropdownButton" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleLevelDropdown} style={{ backgroundColor: '#3d3d3d', borderColor: '#3d3d3d', height:"35px", fontSize:"smaller",  width: "100%", marginBottom:"10px" }}>
                        Level
                    </button>
                    <ul className={`dropdown-menu ${levelDropdownOpen ? 'show' : ''}`} aria-labelledby="levelDropdownButton" >
                        {levels.map((level, index) => (
                            <li key={index} ><a className="dropdown-item" href="#" style={{color:"#3d3d3d", fontWeight:"bold", fontSize:"14px"}}>{level}</a></li>
                        ))}
                    </ul>
                </div>
            </div> */}

        </div>                
    </header>
  )
}
